.info-legend {
  padding: 6px 8px;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  bottom: 55px;
  max-width: 200px;
  word-break: break-word;
}

.legend-container {
  display: flex;
  flex-direction: column;
}
.legend-container > div {
  display: flex;
  align-items: baseline;
}

.hide-control {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background: #fafafa;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}

html {
  width: 100%;
  height: 100%;
}

.d-none {
  display: none;
}

#a11y-status-message {
  /*Sfarfallio finestra di selezione soggetti nel pianificarore */
  margin: 0px !important;
}

.red-border {
  color: red !important;
  font-size: large !important;
}

.leaflet-top {
  top: 110px;
}

.MuiCard-root form {
  padding-bottom: 80px;
}

.MuiTablePagination-spacer {
  flex: none !important;
}

.MuiTablePagination-toolbar {
  position: fixed !important;
  bottom: 13px;
  height: 40px;
  min-height: 40px !important;
  background-color: white;
  width: 100%;
  z-index: 999;
}

.MuiTableCell-sizeSmall {
  padding: 0px 0px 0px 5px !important;
}

.list-page {
  padding-bottom: 55px;
  overflow: initial;
}

.MuiTypography-body2 {
  font-size: 0.8rem;
}

/*
.filter-field>div>div {
  max-width: 150px;
}

.filter-field>div>div>div {
  max-width: none;
}


.filter-field-small {
  max-width: 150px !important;
}

.filter-field-medium>div>div {
  max-width: 200px !important;
}

.filter-field-large>div {
  max-width: 300px !important;
}

.filter-field-no-limit>div {
  max-width: none !important;
}

.red-bg {
  background-color: red;
}
*/

.filter-field-small {
  max-width: 150px !important;
}

.MuiFilledInput-inputMarginDense {
  padding: 0px 0px 0px 0px;
}

.MuiFilledInput-input {
  padding: 17px 12px 10px 0px;
}

.MuiButton-root {
  min-width: 10px !important;
}

#secondary-app-bar {
  max-height: 200px;
  overflow: auto;
}

.leaflet-tooltip-no-bg {
  background-color: transparent;
  border: transparent;
}